import React from 'react';
import Cookies from 'js-cookie'
import { useEffect,useState } from 'react';
import { CardAbenteuer } from '../components/abenteuer/CardAbenteuer';
import { json } from 'react-router-dom';


export const HTML = () => {
  const [abenteuerData, setAbenteuerData] = useState(false);

  useEffect(() => {
    const sessionId = Cookies.get('sessionID');
    const fetchData = async () => {
        // console.log("TESdsadsadT")
      try {
        const response = await fetch('https://backend-codedeer.onrender.com/abenteuer', {
          headers: {
            'session-id': sessionId,
            'language': 'Html',
          },
        })
        
        if (!response.ok) {
          if(response.status === 500){
          //  console.log(response + "TEST2")
             alert("Fehler, loggen Sie sich neu ein.")
          }
         
          throw new Error('Network response was not ok');
        }
        //console.log(response)
        const data = await response.json();
      
        setLocalStoragedata(data);
       console.log(data)
      setAbenteuerData(data);
      //  console.log(data.abenteuer[0].Abenteuer1.status); // Hier kannst du die Daten verwenden oder verarbeiten
      //  console.log(data.abenteuer[1].Abenteuer2.status); 
     
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  
  }, []);

  const handleSelecting = (pageURL) => {
    window.location.href = "#" + pageURL; 
   }

   function setLocalStoragedata(data) {
    if (data && data.abenteuer[0].Abenteuer1) {
      localStorage.setItem("abenteuer1", JSON.stringify(data.abenteuer[0].Abenteuer1));
    }
  }
 //https://blog.logrocket.com/progressive-image-loading-react-tutorial/
  return (
    <div className='container m-0 mt-3'>
   
    <div className='row m-1'>
      {abenteuerData ? ( // Überprüfe, ob abenteuerData vorhanden ist
        <>
          <CardAbenteuer
            cardinfos={{
              path: "abenteuer1",
              header: "Abenteuer1",
              text: "Was ist HTML?",
              isOpen: abenteuerData.abenteuer[0].Abenteuer1.status,
              metaData: abenteuerData.abenteuer[0].Abenteuer1
            }}
          />
          <CardAbenteuer
            cardinfos={{
              path: "aufgabe2",
              header: "Abenteuer2",
              text: "HTML-Tags/ELEMENTE",
              isOpen: abenteuerData.abenteuer[1].Abenteuer2.status,
            }}
          />
        </>
      ) : (
        <CardAbenteuer
        cardinfos={{
          path: "Seite Lädt",
          header: "Seite Lädt",
          text: "Seite Lädt",
          isOpen: 0,
        }}
      />
       // Anzeige während des Ladens
      )}
    </div>
  </div>
  ) 
}
{/* https://codemirror.net/ */}