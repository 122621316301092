import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { StarFill } from 'react-bootstrap-icons';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { useAuth } from '../../contexts/AuthContext'

const NavBar = () => {
  const[stars,setStars] = useState(0);
  const[level,setLevel] = useState(0);
  const[progress,setProgress] = useState(0);
  const {authUser,
    setAuthUser,
     isLoggedIn,
  setisLoggedIn} = useAuth()

  const handlerHref = (pageURL) =>{
    window.location.href =  pageURL; 
  }

  return ( 
  <div>      
    {!isLoggedIn  ? ( 
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand" href="#/home">Codedeer</a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
        <span className="navbar-toggler-icon"></span>
        </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav d-flex align-items-center justify-content-start">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="../#home">Home</a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Programmieren
                </a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href='/#html'>HTML/CSS</a></li>
                  <li><a className="dropdown-item" href='/#java'>JAVA</a></li>
                  <li><a className="dropdown-item" href='/#spickzettel'>SPICKZETTEL</a></li>
                </ul>
              </li>
            </ul>
        <div className="collapse navbar-collapse  justify-content-end" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex align-items-center ">
            <li className='d-flex align-items-center'>
              {stars}
              <StarFill/>
              <progress className="progress-bar ms-2" value={progress} max="100" style={{border: "1px solid #000"}}></progress>
              <span className='mx-2'>Level: {level}</span>     
            </li>
            <li >
              <button onClick={() => handlerHref("#/login")}  className='btn btn-outline-success  type="submit"' style={{width:"100px"}}>
                Login
              </button>
              <button onClick={() => handlerHref("#/signin")}  className='btn btn-outline-success  type="submit"' style={{width:"100px"}}>
                Signin
              </button>
            </li>
          </ul>
          </div>
        </div>
      </div>
  </nav>
  ) : ( 
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
    <div className="container-fluid">
      <a className="navbar-brand" href="#/home">Codedeer</a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="../#home">Home</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Programmieren
              </a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href='/#html'>HTML/CSS</a></li>
                <li><a className="dropdown-item" href='/#java'>JAVA</a></li>
                <li><a className="dropdown-item" href='/#spickzettel'>SPICKZETTEL</a></li>
              </ul>
            </li>
          </ul>
          <div className="collapse navbar-collapse  justify-content-end" id="navbarSupportedContent">
            <ul className="navbar-nav d-flex align-items-center ">
              <li className='d-flex align-items-center'>
                {stars}
                <StarFill className='ms-1'/>
                <progress className="progress-bar ms-1 pb-1" value={progress} style={{border: "1px solid #000"}} max="100"></progress>
                <span className='ms-1' ><b>Level: {level}</b></span>     
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {authUser}
                </a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href='/#html'>Profil</a></li>
                  <li><a 
                  onClick={()=> {
                    sessionStorage.removeItem('username'); 
                    localStorage.removeItem("authUser");
                    localStorage.removeItem("isLoggedIn");
                    Cookies.remove('sessionID')
                    setisLoggedIn(false)
                    setAuthUser(null)
                  }} 
                  className="dropdown-item" href='/#home'>logout</a></li>
                </ul>
              </li>  
            </ul>
          </div>
        </div>
      </div>
    </nav> 
    )}
 </div>
  )}
export default NavBar
