import React from 'react'
import cssclass from '../../css/englisch.css'


const Spickzettel = () => {
  return (
    <body>
    <div >
      <h1>HTML</h1>
      <div class="TABLEcontainer">
        <table>
        <thead>
            <tr>
                <th>Beispiele</th> 
                <th>Englisch</th>
                <th>Deutsch</th> 
            </tr>
        </thead>
      <tbody> 
        <tr class="attribut"> 
                <td>Tags</td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>&lt;html&gt;</td>
                <td>Hypertext Markup Language</td>
                <td>Hypertext-Auszeichnungssprache</td>
            </tr>
            <tr>
                <td>&lt;head&gt;</td>
                <td>head</td>
                <td>Kopf</td>
            </tr>
            <tr>
                <td>&lt;body&gt;</td>
                <td>body</td>
                <td>Körper</td>
            </tr>
            <tr>
                <td>&lt;p&gt;</td>
                <td>paragraph</td>
                <td>Absatz</td>
            </tr>
            <tr>
                <td>&lt;img&gt;</td>
                <td>image</td>
                <td>Bild</td>
            </tr>
            <tr>
                <td>&lt;a&gt;</td>
                <td>anker</td>
                <td>Verlinkung</td>
            </tr>
            <tr>
                <td>&lt;ul&gt;</td>
                <td>unordered list</td>
                <td>Ungeortneteliste</td>
            </tr>

            <tr>
                <td>&lt;ol&gt;</td>
                <td>ordered list</td>
                <td>Geortneteliste</td>
            </tr>

            <tr>
                <td>&lt;li&gt;</td>
                <td>list element</td>
                <td>Listenelement</td>
            </tr>

            <tr>
                <td>&lt;div&gt;</td>
                <td>division</td>
                <td>Bereich</td>
    
            </tr>

            <tr>
                <td>&lt;span&gt;</td>
                <td>span</td>
                <td>Bereich</td>
            </tr>

            <tr>
                <td>&lt;hr&gt;</td>
                <td>horizotale rule</td>
                <td>Horizontalelinie</td>
            </tr>

            <tr>
                <td>&lt;br&gt;</td>
                <td>break</td>
                <td>Zeilenumbruch</td>
            </tr>

            <tr class="attribut">
                <td>Attribut</td>
                <td></td>
                <td></td>
            </tr>

            <tr>
                <td>href=""</td>
                <td>hypertext reference</td>
                <td>Hypertextreferenz(Link)</td>
            </tr>

            <tr>
                <td>src=""</td>
                <td>source</td>
                <td>Quelle</td>
            </tr>

            <tr>
                <td>alt=""</td>
                <td>alternative</td>
                <td>alternativer Text</td>
            </tr>

            <tr class="attribut">
                <td>&Zeichenkombinationen</td>
                <td></td>
                <td></td>
            </tr>

            <tr>
                <td>&,lt</td>
                <td>less than</td>
                <td>kleiner als</td>
            </tr>

            <tr>
                <td>&,gt</td>
                <td>greater than</td>
                <td>größer als</td>
            </tr>
            <tr>
                <td>&,nbsp</td>
                <td>no break space</td>
                <td>Leerzeichen</td>
            </tr>
            <tr>
                <td>&,copy</td>
                <td>copyright</td>
                <td>copyright</td>
            </tr>
        </tbody>
    </table>

    <h1>CSS</h1>
    <div class="tabellecss">
    <table>
        <thead>
            <tr>
                <th>Beispiele</th> 
                <th>Englisch</th> 
                <th>Deutsch</th> 
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>margin: 0;</td>
                <td>margin</td>
                <td>Abstand</td>
            </tr>
            <tr>
                <td>padding: 0;</td>
                <td>padding</td>
                <td>Innenabstand</td>
            </tr>
            <tr>
                <td>background-color: ;</td>
                <td>background-color</td>
                <td>Hintergrundfarbe</td>
            </tr>
            <tr>
                <td>display</td>
                <td>display</td>
                <td>Anzeige(Darstellung)</td>
            </tr>
            <tr>
                <td>border: 1px solid rgb(255, 255, 255);</td>
                <td>border</td>
                <td>Rahmen(Rand)</td>
            </tr>
            <tr>
                <td>font-size: 16px;</td>
                <td>font-size</td>
                <td>Schritgröße</td>
            </tr>
            <tr>
                <td>color</td>
                <td>color</td>
                <td>Farbe</td>
            </tr>
            <tr>
                <td>flex</td>
                <td>flex</td>
                <td>flexibles Layout</td>
            </tr>
            <tr>
                <td>text-align: center;</td>
                <td>align</td>
                <td>ausrichten</td>
            </tr>
        </tbody>
    </table>
    </div>
    </div>
  
    </div>
    </body>
  )
}

export default Spickzettel