import React, { useState } from 'react';

const Quiz = ({header, onQuizVisibilityChange}) => {

  const [questions, setQuestions] = useState([
    {
      question: 'Frage 1: Was ist HTML?',
      options: ['Eine Programmiersprache', 'Eine Auszeichnungssprache', 'Eine Datenbank'],
      correctAnswer: 'Eine Auszeichnungssprache',
      selectedAnswer: 1,
    },
    {
      question: 'Frage 2: Welches Element wird verwendet, um einen Absatz in HTML zu erstellen?',
      options: ['<p>', '<a>', '<h1>'],
      correctAnswer: '<p>',
      selectedAnswer: 0,
    },
    // Weitere Fragen hier hinzufügen
  ]);
  
 // const [header, setHeader] = useState(header);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [rightQuestion, setrightQuestion] = useState(0); // Hier wird die Punktzahl gespeichert
  const [score, setScore] = useState(0); // Hier wird die Punktzahl gespeichert
  const [option, setOption] = useState(0); // Hier wird die Punktzahl gespeichert
  const [isQuizFinish, setQuizFinished] = useState(false); 

  const handleOptionClick = () => {
    const updatedQuestions = [...questions];
    updatedQuestions[currentQuestionIndex].selectedAnswer = option;
    if (option ===rightQuestion) {
      setScore(score + 1);
    }
    setQuestions(updatedQuestions);
  };

  const handleNextQuestion = () => {

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // Alle Fragen beantwortet, hier können Sie die Auswertung vornehmen oder weitere Aktionen ausführen
     // alert(`Quiz beendet! Punktzahl: ${score}`);

     setQuizFinished(true)
    }
    handleOptionClick();
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">{header}</h2>
          <p className="card-text">{questions[currentQuestionIndex].question}</p>
          <ul className="list-group">
            {questions[currentQuestionIndex].options.map((option, index) => (
              <li className="list-group-item" key={index}>
                <label className="form-check-label">
                  <input
                      type="radio"
                      className="form-check-input"
                      name={`question${currentQuestionIndex}`}
                      value={option}
                      onChange={() => {
                        if(!isQuizFinish){
                          setOption(option);
                          setrightQuestion(questions[currentQuestionIndex].correctAnswer);
                        }
                    }}
                  />
                  <span className="option-text ms-2">{option}</span>
                </label>
              </li>
            ))}
          </ul>
          <button hidden={isQuizFinish} className="btn btn-primary mt-3" onClick={handleNextQuestion}>
            {currentQuestionIndex < questions.length - 1 ? 'Nächste Frage' : 'Quiz beenden'}
          </button>
        </div>
      </div>
      <div className="mt-4">
      {!isQuizFinish  ? (
        <p>Punktzahl: {score}/{questions.length}</p>
      ) : (
        <>
          <p>QUIZ BEENDET!</p>
          <p>Punktzahl: {score}/{questions.length}</p>
         <button  className='btn btn-primary mb-4' onClick={() => onQuizVisibilityChange(false)}>Weiter</button>
        </>
      )}
      </div>
     
    </div>
  );
};

export default Quiz;
