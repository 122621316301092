import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const CookieConsent = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '' });
  const [cookieAccepted, setCookieAccepted] = useState(false);

  useEffect(() => {
    // Überprüfe den Cookie-Status, wenn die Komponente montiert wird
    const cookieStatus = localStorage.getItem('cookieAccepted');
    if (cookieStatus === 'true') {
      setCookieAccepted(true);
    } else {
      setShowModal(true); // Zeige das Modalfenster, wenn der Cookie-Status nicht akzeptiert ist
    }
  }, []);

  const handleAcceptCookie = () => {
    // Setze den Cookie-Status auf akzeptiert
    localStorage.setItem('cookieAccepted', 'true');
    setCookieAccepted(true);
    setShowModal(false); // Schließe das Modalfenster
  };

  const handleCloseModal = () => {
    setShowModal(true);
    window.close();
  
  };

  return (
    <div>
      {!cookieAccepted && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Cookie-Zustimmung</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Diese Website verwendet essentielle Cookies für eine bessere
              Erfahrung.
            </p>
            <Button variant="primary" onClick={handleAcceptCookie}>
              Zustimmen
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default CookieConsent;
