import React from 'react';

const NavbarAbenteuer = ({header ,metaDaten}) => {

  //console.log("META:::")
  //console.log( metaDaten)
  //console.log("content:::")
  //console.log( content)
  console.log(header)

  const onClick = (e,aufgabe) => {
    console.log("aufgabe" + aufgabe)
    if(aufgabe === "1" ){
       e.preventDefault()
      console.log('onclick..')
    }
   
  }
  //Überschriften
  //links zu Seiten ?
  console.log("test")
  const navbarStyle = {
    border: 'none', // Entfernen der Border
  };

  const navItemStyle = {
    marginRight: '20px', // Abstand zwischen den Navbar-Elementen
  };

  const navLinkStyle = {
    textDecoration: 'none', // Entfernen der standardmäßigen Textunterstreichung
  };
  const navstyleacces = {
    cursor: 'pointer' 
  };
  const activeLinkStyle = {
    borderBottom: '2px solid #007BFF',
    cursor: 'pointer' // Hinzufügen einer Unterstreichung für das ausgewählte Element
  };
  const listeDerElemente = header.map((element, index) => (
    <li className="nav-item" style={navItemStyle} key={index}>{element}
        <a className="nav-link"  
            onClick={(e) => {onClick(e,metaDaten.aufgabe1)}}  
            style={{ ...(metaDaten.aufgabe1 === "1" ? navstyleacces : {}), ...navLinkStyle}} >   
            {element}{" >>"}
        </a>
     </li>
  ));
  return (
    <nav className="navbar navbar-expand navbar-light " style={navbarStyle}>
             {/* <a  href={metaDaten.aufgabe2===1 ? "#" : "javascript:void(0)"} style={navLinkStyle}>
            Syntax{" >>"}
          </a> */}
        {/**/}
      <ul className="navbar-nav">
        {listeDerElemente}
      {/* <li className="nav-item" style={navItemStyle}>
        <a className="nav-link"  
            onClick={(e) => {onClick(e,metaDaten.aufgabe1)}}  
            style={{ ...(metaDaten.aufgabe1 === "1" ? navstyleacces : {}), ...navLinkStyle}} >   
            HTML{" >>"}
        </a>
        </li>

         <li className="nav-item" style={navItemStyle}>
        <a className="nav-link"  
            onClick={(e) => {onClick(e,metaDaten.aufgabe2)}}  
            style={{ ...(metaDaten.aufgabe2 === "1" ? navstyleacces : {}), ...navLinkStyle}} >   
            Browser{" >>"}
        </a>
        </li>
        <li className="nav-item" style={navItemStyle}>
          <a className="nav-link" style={navLinkStyle}>
            Syntax{" >>"}
          </a>
        </li>
        <li className="nav-item" style={navItemStyle}>
          <a className="nav-link" style={navLinkStyle}>
            Kontakt
          </a>
        </li>  */}
      </ul>
    </nav>
  );
}

export default NavbarAbenteuer;
