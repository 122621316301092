import React, { useState } from 'react';
import { TextWindow } from './TextWindow';
import Quiz from './Quiz';
import NavbarAbenteuer from '../abenteuer/NavbarAbenteuer'
import '../../styles/aufgabe1.css'
export const Abenteuer = ({ content ,metaData }) => {

  const abenteuer = content.find(item => item.ID === 1);
 
  const [inhalt,setInhalt] = useState(abenteuer.content[0].body);
  const [header,setHeader] = useState(abenteuer.content[0].header);
  const [embedId,setEmbedId] = useState(abenteuer.content[0].embedId);
  const [isVideo, setisVideo] = useState(false);
  const [isQuiz, setisQuiz] = useState(false);
  const [metaDaten, setMetaData] = useState(metaData);

  const headerNavbar = abenteuer.content.map(item => item.header);
// console.log(headerNavbar)
 
  return (
    <div className="container">
      <div className="col-11 col-sm-11  col-md-11 col-lg-11" >  
        <NavbarAbenteuer header={headerNavbar} metaDaten={metaDaten} ></NavbarAbenteuer>
      </div>
      <div className="row  justify-content-center"> 
           {/* <div  hidden={isQuiz}  className="col-sm-12 col-md-3 col-lg-2 "  style={{textAlign:'left' }}> 
            <ol> 
              {abenteuer.content.map((element, index) => (
                <li key={index}>
                    <StyledLink text={abenteuer.content[index].header} onClick={() => { 
                      setInhalt(abenteuer.content[index].body) 
                      setHeader(abenteuer.content[index].header) 
                      setEmbedId(abenteuer.content[index].embedId)
                      }} />
                </li>
              ))}
            </ol>
           </div> */}
          <div className='container' hidden={!isQuiz} style={{maxWidth:'700px'}}>
            <Quiz header={header} onQuizVisibilityChange={() => setisQuiz()} />
          </div>   
          <div className="col-11 col-sm-11  col-md-11 col-lg-11" style={{ wordWrap: 'break-word',textAlign:'left' }}>
              <div hidden={isQuiz}>
                <TextWindow hidden={isVideo}  content={inhalt} header={header} embedId={embedId}></TextWindow>  {/* <p>createdCOMPONENT{inhalt}</p> */}
             </div> 
             <div  hidden={isQuiz} className='container mt-2 mb-5'>
              <button className='button bg-primary' style={{width:'95px'}} onClick={() => setisVideo(true)}>Video fertig</button>   
              <button className='button bg-primary' style={{width:'100px'}} disabled={!isVideo} onClick={() => setisQuiz(true)}>Quiz starten</button>    
             </div>
          </div>         
      </div>
    </div>
  )
}
