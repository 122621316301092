import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export const Footer = () => {
  return (
    <footer className="bg-dark text-light py-4">
      <Container>
        <Row>
          <Col lg={4} md={6}>
            <h5>Contact Us</h5>
            <p>Email: contact@example.com</p>
            <p>Phone: +123-456-7890</p>
          </Col>
          <Col lg={4} md={6}>
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="/">Impressum</a></li>
              <li><a href="/about">Datenschutz</a></li>
              <li><a href="/services">Services</a></li>
            </ul>
          </Col>
          <Col lg={4} md={12}>
            <h5>Follow Us</h5>
            <ul className="list-unstyled">
              <li><a href="https://www.youtube.com/@codedeer"><i className="fab fa-facebook"></i> Youtube</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="text-center py-3">
        <p>&copy; {new Date().getFullYear()} codedeer. All rights reserved.</p>
      </div>
    </footer>
  );
};

 
