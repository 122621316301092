import React, {useState,useEffect,useContext} from "react";

const AuthContext = React.createContext()

export function useAuth(){
  return useContext(AuthContext);
}

export function AuthProvider(props){
  const [authUser,setAuthUser] = useState(null)
  const [isLoggedIn,setisLoggedIn] = useState(false)

  useEffect(() => {
    // Check if there's authentication data in localStorage
    const storedAuthUser = localStorage.getItem("authUser");
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");

    if (storedAuthUser && storedIsLoggedIn) {
      console.log(storedAuthUser)
      setAuthUser(storedAuthUser);
      setisLoggedIn(true);
    }
  }, []);

  const value = {
    authUser,
    setAuthUser,
    isLoggedIn,
    setisLoggedIn
  }

  return(
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  )
}