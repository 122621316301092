import { useState } from "react";
import {Abenteuer} from "../../components/abenteuer/Abenteuer"
import jsonArr from "../../data/abeteuer/abenteuer1.json";

export const Abenteuer1 = () => {

const [metaData,setMetaData] = useState(JSON.parse( localStorage.getItem("abenteuer1")));
 //console.log("------------------")
 //console.log(jsonArr)
 //console.log("------------------")
  return (
     <Abenteuer content={jsonArr} metaData={metaData}/>
  )
}

