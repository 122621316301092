import React, { useState } from 'react'
import placholderSrc from '../../images/htmlsmall.jpg'
import ProgressiveImg from '../ProgressiveImg';
import cardPic from '../../images/html.jpg'

export const CardAbenteuer = ({cardinfos}) => {
console.log(cardinfos)
const [path,setPath] = useState(false);

  const handleSelecting = (pageURL) => {
    window.location.href = "#" + pageURL; 
  }
  
  return (
    <div onClick={()=>{
      if(cardinfos.isOpen == 1){
        setPath(cardinfos.path)
        handleSelecting(cardinfos.path)
      } else {
        setPath("html")
        alert("Dieses Abenteuer ist noch geschlossen.")
      }
    }}  
    className="card me-3"  
    style={{ width:"18rem", cursor:'pointer'}}
    >
      <ProgressiveImg alt="..." src={cardPic} placeholderSrc={placholderSrc} 
      style={{ 
      width:"16rem", 
      cursor:'pointer',
      height:'222px'}} 
      /> 
      <div  className="card-body">
        <h5 className="card-title">{cardinfos.header}</h5>
        <p className="card-text">{cardinfos.text}</p>
        <a href={"/#"+path} className="btn btn-primary">Los geht's!</a>
      </div>
    </div>
  )
}
