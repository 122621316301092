import React, { useState } from 'react';
import Cookies from 'js-cookie'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Home} from '../../pages/Home'
import { Modal} from 'react-bootstrap'; // Import Bootstrap Modal and Button components
import { useAuth } from '../../contexts/AuthContext'

const Login = () => {
  const [input, setInput] = useState({
    Benutzer: '',
    Password: '',
  });

  const {
    authUser,
    setAuthUser,
     isLoggedIn,
  setisLoggedIn} = useAuth()

  const [showModal, setShowModal] = useState(true); // State for showing/hiding the modal
  const [showSpinner, setShowSpinner] = useState(false); // State for showing/hiding the modal

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = (e) => {
    setShowSpinner(true);
    e.preventDefault();
    fetch('https://backend-codedeer.onrender.com/login', {//process.env.API_LOGIN, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input),
    })
      .then((response) => response.json()) // Change 'response' to 'data' here
      .then((data) => {
        //console.log(data)
        if ( data.status === 200) { // Change 'response' to 'data' here
          setShowSpinner(false);
          Cookies.set('sessionID', data.SessionID, { expires: 7 });
          console.log(Cookies.get('sessionID'))
          setisLoggedIn(true)
          setAuthUser(input.Benutzer)     
          sessionStorage.setItem('username', input.Benutzer); 
          localStorage.setItem("authUser", input.Benutzer);
          localStorage.setItem("isLoggedIn", "true");
          window.location.href = "#" + "home"; 
          handleCloseModal();
        } else {
          // Handle login errors
          if (data.status === 401) { // Change 'response' to 'data' here
            alert("Benutzername oder Password falsch.");
          } else {
            alert("Fehler beim Einloggen");
          }
          setShowSpinner(false);
        }
      })
      .catch((error) => {
        alert("Fehler beim Einloggen: " + error);
      });
  };

  return (
    <>
    <Modal  show={showModal} onHide={handleCloseModal}>
      <div className="container h-100">
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-md-6">
            <div className="card p-4" style={{ backgroundColor: 'rgba(200, 200, 200, 0.8)' }}>
              <h2 className="text-center">Login</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="Benutzer" className="form-label">Benutzername oder E-Mail:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Benutzer"
                    name="Benutzer"
                    value={input.Benutzer}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Password" className="form-label">Passwort:</label>
                  <input
                    type="Password"
                    className="form-control"
                    id="Password"
                    name="Password"
                    value={input.Password}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary" onClick={handleShowModal}>
                    Einloggen
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div>
        { showSpinner? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Bitte warten...</span>
          </div>
        ) : (
          <div></div>
        )}
          <div></div>
      </div>
    </Modal>
    <Home/>
    </>
  );
};

export default Login;
