import React from 'react';
import cardPic from '../images/html.jpg'
import placholderSrchtml from '../images/htmlsmall.jpg'
import placholderSrcjava from '../images/javasmall.jpg'
import java from '../images/java.png'
import ProgressiveImg from '../components/ProgressiveImg';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { Carousel } from '../components/mainframe/Carousel';
import { useAuth } from '../contexts/AuthContext'

export const Home = () => {
 
  const {authUser,setAuthUser,isLoggedIn,setisLoggedIn} = useAuth();

  const[session,setSession] = useState(Cookies.get('sessionID'));
  const handleSelecting = (pageURL) => {window.location.href = "#" + pageURL;}
  
  return (
  <div className='container'> 
    <div className='row m-1 container justify-content-center text-center '>
      { (!isLoggedIn) ?
        <Carousel></Carousel> : <h2 className=''>Hi {authUser} wähle deine Sprache aus.</h2>
      }
        <div onClick={()=>{handleSelecting("/html")}}  className="card me-2"  style={{width:"18rem", cursor:'pointer'}}>
          <ProgressiveImg alt="..." src={cardPic} placeholderSrc={placholderSrchtml} 
            style={{ 
              width:"16rem", 
              cursor:'pointer',
              height:'222px'}} 
          /> 
          <div  className="card-body ">
            <h5 className="card-title">HTML/CSS</h5>
            <p className="card-text">Begebe dich auf die Reise zum <b>Webentwickler</b>.</p>
            <a href="/#aufgaben" className="btn btn-primary">Los geht's!</a>
          </div>
        </div>
        <div  onClick={()=>{handleSelecting("/java")}}  className="card  me-2"  style={{width:"18rem", cursor:'pointer'}}>
          <ProgressiveImg alt="..." src={java} placeholderSrc={placholderSrcjava} 
            style={{ 
              width:"16rem", 
              cursor:'pointer',
              height:'205px'}} 
          /> 
          {/* <img src={java} className="card-img-top mt-5 mb-3" alt="..."></img> */}
          <div className="card-body">
            <h5 className="card-title mt-3">Java</h5>
            <p className="card-text">Begebe dich auf die Reise zum <b>Javaentwickler</b>.</p>
            <a href="/#aufgabe2" className="btn btn-primary">Los geht's!</a>
          </div>
        </div>
    </div>
      https://codemirror.net/
  </div>
  )
}
