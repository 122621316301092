import DOMPurify from 'dompurify'

export const TextWindow = ({content,header,embedId}) => {   

  const zeile1 = `${content.zeile1}`
  const zeile2 = `${content.zeile2}`
  return (
    <div className="container" style={{ }}>
    <h4 style={{ textAlign: 'left', marginTop: '10px' }}>{header}</h4>
    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(zeile1)}}></p>
    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(zeile2)}}></p>
    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
      {/* 16:9 Seitenverhältnis - Anpassen, wenn dein Video ein anderes Seitenverhältnis hat */}
      <iframe
        id="youtubeIframe"
        src={`https://www.youtube.com/embed/${embedId}`}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border:'1px solid black' }}
      />
    </div>
  </div>
  )
}
