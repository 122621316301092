import React from 'react'
import image1 from '../../images/coding.jpg'
import video from '../../videos/hello.mp4'

export const Carousel = () => {
  return (
    <div className='container' style={{width: "85%"}}>
      <div id="carouselExampleCaptions" className="carousel slide" datain="1000">
      <div className="carousel-indicators ">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
        <video className="d-block w-100"  autoPlay loop>
              <source src={video} type="video/mp4"  />
              Your browser does not support the video tag.
            </video>
          <div className="carousel-caption d-none d-md-block">
            <h5>Lass uns gleich anfangen!</h5>
            <p>Wähle den passenden Kurs für dich aus!.</p>
          </div>
        </div>
        <div className="carousel-item">
      <img src={image1} className="d-block w-100" alt="..."></img>
          <div className="carousel-caption d-none d-md-block">
            <h5>Worauf wartest du?</h5>
            <p>Geb nicht auf, bleib dran, es lohnt sich!.</p>
          </div>
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  )
}
