import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import NavBar from './components/mainframe/NavBar';
import {Quiz} from './pages/Quiz';
import {Aufgabe1}  from './pages/aufgaben/Aufgabe1';
import {Aufgabe1h}  from './pages/aufgaben/Aufgabe1h';
import {Aufgabe2}  from './pages/aufgaben/Aufgabe2';
import {HashRouter,Routes,Route} from 'react-router-dom'
import { Home } from './pages/Home';
import { HTML } from './pages/HTML';
import { Ptag } from './pages/erklärungen/Ptag';
import { Htmltags } from './pages/erklärungen/Htmltags';
import { JAVA } from './pages/JAVA';
import { Footer } from './components/mainframe/Footer';
import CookieConsent from './components/mainframe/CookieConsent';
import Login from './components/mainframe/Login';
import SignIn from './components/mainframe/SignIn';
import { AuthProvider } from './contexts/AuthContext';
import { Abenteuer1 } from './pages/abenteuer/Abenteuer1';
import { Test } from './pages/abenteuer/Test';
import Spickzettel from './pages/erklärungen/Spickzettel';
function App() {
 
  return (
    <>    
   <AuthProvider>
    <NavBar/> 
   {/* <Dashboard/> */}
    <CookieConsent/>
    <div className='container'>
      {/* <Aufgabe1/> */}
      <HashRouter>
       <Routes> 
        <Route index element={<Home/>}></Route>
        <Route path='/home'      element={<Home/>}></Route>
        <Route path='/login'      element={<Login/>}></Route>
        <Route path='/signin'      element={<SignIn/>}></Route>
        <Route path='/html'      element={<HTML/>}></Route>
        <Route path='/java'      element={<JAVA/>}></Route>
        <Route path='/spickzettel'  element={<Spickzettel/>}></Route>
        <Route path='/aufgabe1'  element={<Aufgabe1/>}></Route>
        <Route path='/aufgabeh'  element={<Aufgabe1h/>}></Route>
        <Route path='/aufgabe2'  element={<Aufgabe2/>}></Route>
        <Route path='/htmltags'  element={<Htmltags/>}></Route>
        <Route path='/abenteuer1'  element={<Abenteuer1/>}></Route>
        <Route path='/test' element={<Test></Test>}></Route>
        <Route path='/ptag'  element={<Ptag/>}></Route>
        <Route path='/quiz'  element={<Quiz/>}></Route>
      </Routes> 
     </HashRouter>
    </div>
      {/* https://letsencrypt.org/de/getting-started/ */}
   <Footer></Footer>
   </AuthProvider>
   </>
  );
}

export default App;
